import Vue from 'vue'
import VueRouter from 'vue-router'
import Overview from '../views/Overview.vue'
import Account from '../views/Account.vue'
import { authGuard } from "../auth/authGuard";
import { authGuardInternalOnly } from "../auth/authGuardInternalOnly"

Vue.use(VueRouter)

const routes = [{
        path: '/overview',
        name: 'Overview',
        component: Overview,
        beforeEnter: authGuardInternalOnly
    },
    {
        path: '/',
        redirect: '/audits',
        beforeEnter: authGuard,

    },
    {
        path: '/audits',
        name: 'Audits',
        beforeEnter: authGuard,
        component: () =>
            import ('../views/Audits.vue')
    },
    {
        name: 'Findings',
        path: '/audits/:auditId',
        beforeEnter: authGuard,
        component: () =>
            import ('../views/Findings.vue')
    },
    {
        path: '/account',
        name: 'Account',
        beforeEnter: authGuard,
        component: Account
    },
    {
        path: '/upcoming',
        name: 'Upcoming',
        beforeEnter: authGuardInternalOnly,
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Upcoming.vue')
    },
    {
        path: '/inbox',
        name: 'Inbox',
        beforeEnter: authGuardInternalOnly,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Inbox.vue')
    },
    {
        path: '/accounts',
        name: 'Accounts',
        beforeEnter: authGuardInternalOnly,
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Accounts.vue')
    },
    {
        path: '/companies',
        name: 'Companies',
        beforeEnter: authGuardInternalOnly,
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Companies.vue')
    },
    {
        path: '/companies/:companyId',
        name: 'Companies',
        beforeEnter: authGuardInternalOnly,
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Company.vue')
    },
    {
        path: '/logs',
        name: 'Logs',
        beforeEnter: authGuardInternalOnly,
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Logs.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router