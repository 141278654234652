import { getInstance } from "./index";
import router from '../router/index'

export const authGuardInternalOnly = (to, from, next) => {
    const authService = getInstance();

    const fn = () => {
        // If the user is authenticated and their role is internal, continue with the route
        if (authService.isAuthenticated && authService.user[`https://skyswarm.sky360.com.au/role`] == `internal`) {
            return next();
        } else {
            // Otherwise, redirect them to home/overview (this will prompt authentication anyways)
            router.push({ path: '/audits' });
            return;
        }
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
        return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", loading => {
        if (loading === false) {
            return fn();
        }
    });
};