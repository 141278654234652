import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
Vue.config.productionTip = false;

// Styling
import Vuesax from 'vuesax';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vuesax/dist/vuesax.css';
Vue.use(VueGoodTablePlugin);
Vue.use(Vuesax, {
    colors: {
        primary: 'rgb(245, 150, 28)',
        success: 'rgb(23, 201, 100)',
        danger: 'rgb(242, 19, 93)',
        warning: 'rgb(255, 130, 0)',
        dark: 'rgb(36, 33, 69)'
    }
});
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Auth0
import { domain, clientId } from "../auth_config.json";
import { Auth0Plugin } from "./auth";
import { getInstance } from '@/auth/index.js';
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl ?
            appState.targetUrl :
            window.location.pathname
        );
    }
});

// Composition API
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI);

// Filters
Vue.filter('formatDate', function(date) {
    if (date != undefined) {
        const day = date.substring(8);
        const month = date.substring(5, 7);
        const year = date.substring(0, 4);
        return `${day}/${month}/${year}`;
    }
});

// Apollo
import VueApollo from "vue-apollo";
import ApolloClient from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
const getHeaders = async() => {
    const headers = {};
    const authService = getInstance();

    const getToken = async() => {
        if (authService.isAuthenticated) {
            const token = await authService.getTokenSilently();
            headers.authorization = token ? `Bearer ${token}` : '';
            return headers;
        }
    };

    // If loading has already finished, check our auth state using `getToken()`
    if (!authService.loading) {
        return getToken();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch('loading', loading => {
        if (loading === false) {
            return getToken();
        }
    });
};


// Create a WebSocket link:
const link = new WebSocketLink({
    uri: 'wss://graphql.api.skyswarm.sky360.com.au/v1/graphql',
    options: {
        reconnect: true,
        timeout: 30000,
        connectionParams: () => {
            return { headers: getHeaders() };
        },
    }
});
const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache({
        addTypename: true
    })
});
Vue.use(VueApollo);
const apolloProvider = new VueApollo({
    defaultClient: client,
})

// Context menu
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';
import VueSimpleContextMenu from 'vue-simple-context-menu';
Vue.component('vue-simple-context-menu', VueSimpleContextMenu);

new Vue({
    router,
    store,
    apolloProvider,
    render: h => h(App)
}).$mount('#app');