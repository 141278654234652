<template>
  <div>
    <h2>Account</h2>
    <div v-if="!$auth.loading">
      <h5>{{ $auth.user.name }}</h5>
      <p>
        Email: {{ $auth.user.email }} <br />
        Company: {{ $auth.user[`https://skyswarm.sky360.com.au/company`] }}
        <br />
        Permission level:
        {{ $auth.user[`https://skyswarm.sky360.com.au/role`] }}
      </p>
      <b-button @click="active2 = !active2">Reset password</b-button>
      <b-button @click="showChat">Support</b-button>
    </div>
    <b-modal
      title="Reset your password?"
      not-center
      auto-width
      v-model="active2"
    >
      <p>This will send you an email with a link to set a new password.</p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Account",
  data() {
    return {
      active2: false,
    };
  },
};
</script>

<style>
.vs-button {
  margin: 0 0 0 0;
}
</style>
