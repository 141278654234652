<template>
  <div class="divsidebar" v-if="!$auth.loading">
    <vs-sidebar
      square
      v-model="active"
      class="ssidebar"
      open
      :reduce="isMobile"
      :hover-expand="isMobile"
      style="background-color: #111f2b"
      notShadow
    >
      <template #logo>
        <img v-if="!isMobile" src="../assets/sky360text.png" />
        <img v-if="isMobile" src="../assets/sky360.png" style="width: 25px" />
      </template>

      <!-- <vs-sidebar-item
        v-if="$auth.isAuthenticated"
        style="margin-bottom: 0 !important;"
      >
        <div style="width: 228px">
          <b-alert style="width: 228px" show variant="light"
            ><h4 class="alert-heading" style="font-size: 20px">Alert</h4>
            <p class="mb-0">
              SkySwarm will be down for maintenance tomorrow
            </p></b-alert
          >
        </div>
      </vs-sidebar-item> -->

      <vs-sidebar-item id="audits" to="/audits" v-if="$auth.isAuthenticated">
        <template #icon>
          <b-icon-list-ul></b-icon-list-ul>
        </template>
        Audits
      </vs-sidebar-item>

      <vs-sidebar-item
        id="accounts"
        to="/accounts"
        v-if="$auth.isAuthenticated"
      >
        <template #icon>
          <b-icon-people-fill></b-icon-people-fill>
        </template>
        Accounts
      </vs-sidebar-item>

      <vs-sidebar-item
        id="companies"
        to="/companies"
        v-if="
          $auth.isAuthenticated &&
          $auth.user[`https://skyswarm.sky360.com.au/role`] == `internal`
        "
      >
        <template #icon>
          <b-icon-briefcase-fill></b-icon-briefcase-fill>
        </template>
        Companies
      </vs-sidebar-item>

      <vs-sidebar-item id="logs" to="/logs" v-if="$auth.isAuthenticated">
        <template #icon>
          <b-icon-terminal-fill></b-icon-terminal-fill>
        </template>
        Logs
      </vs-sidebar-item>

      <vs-sidebar-item
        id="sharepoint"
        href="https://sky360comau.sharepoint.com"
        v-if="
          $auth.isAuthenticated &&
          $auth.user[`https://skyswarm.sky360.com.au/role`] == `internal`
        "
      >
        <template #icon>
          <b-icon-arrow-up-right-square-fill></b-icon-arrow-up-right-square-fill>
        </template>
        SharePoint
      </vs-sidebar-item>

      <vs-sidebar-group
        v-if="$auth.user[`https://skyswarm.sky360.com.au/role`] == `internal`"
      >
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <b-icon-three-dots></b-icon-three-dots>
            </template>
            More...
          </vs-sidebar-item>
        </template>
        <vs-sidebar-item id="overview" to="/" v-if="$auth.isAuthenticated">
          <template #icon>
            <b-icon-house-fill></b-icon-house-fill>
          </template>
          Overview
        </vs-sidebar-item>
        <vs-sidebar-item
          id="upcoming"
          to="/upcoming"
          v-if="$auth.isAuthenticated"
        >
          <template #icon>
            <b-icon-calendar-fill></b-icon-calendar-fill>
          </template>
          Upcoming
        </vs-sidebar-item>
        <vs-sidebar-item id="inbox" to="/inbox" v-if="$auth.isAuthenticated">
          <template #icon>
            <b-icon-inbox-fill></b-icon-inbox-fill>
          </template>
          Inbox
          <span v-if="unreadcount > 0" class="unread">{{ unreadcount }}</span>
        </vs-sidebar-item>
      </vs-sidebar-group>

      <div v-if="!$auth.isAuthenticated" @click="login">
        <vs-sidebar-item id="login">
          <template #icon>
            <b-icon-box-arrow-in-right-fill></b-icon-box-arrow-in-right-fill>
          </template>
          Sign In
        </vs-sidebar-item>
      </div>

      <vs-sidebar-group id="accSection">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <b-avatar
                variant="light"
                :text="
                  $auth.user.name
                    .split(' ')
                    .map(function (item) {
                      return item[0];
                    })
                    .join('')
                "
                size="2rem"
                class="ml-2"
              ></b-avatar>
            </template>
            <div class="ml-2">
              {{ $auth.user.name }}<br />
              <div class="company">
                {{ $auth.user[`https://skyswarm.sky360.com.au/company`] }}
              </div>
            </div>
          </vs-sidebar-item>
        </template>

        <vs-sidebar-item id="account" to="/account">
          <template #icon>
            <i class=" "></i>
          </template>
          Account
        </vs-sidebar-item>

        <div v-if="$auth.isAuthenticated" @click="logout">
          <vs-sidebar-item id="signout">
            <template #icon>
              <i class=" "></i>
            </template>
            Sign Out
          </vs-sidebar-item>
        </div>
      </vs-sidebar-group>
    </vs-sidebar>
  </div>
</template>

<script>
export default {
  data: () => ({
    unreadcount: 0,
    name: "",
    company: "",
    isMobile: false,
    claims: undefined,
  }),
  computed: {
    active: {
      get() {
        return this.$route.path.split("/")[1];
      },
      set(newActive) {
        return newActive;
      },
    },
  },
  methods: {
    navigateToOverview() {
      this.$router.push({ path: "/about" });
    },
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    getClaims() {
      this.$auth.getIdTokenClaims().then((claimsresponse) => {
        this.claims = claimsresponse;
      });
    },
  },
  created() {
    if (screen.width > 768) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.active = window.location.pathname.split("/")[1];
  },
};
</script>

<style>
.vs-sidebar__item__text {
  font-family: "Manrope", sans-serif !important;
}
.company {
  font-size: 85%;
  font-weight: normal;
  line-height: 1.25rem;
}
.unread {
  position: absolute;
  right: 15px;
  float: right;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  background: red;
  color: white;
  font-size: 12px;
}
#accSection {
  margin-top: auto;
  margin-bottom: 10px;
  padding-right: 5px;
}
.vs-sidebar-content {
  z-index: 1040 !important;
}
.vs-sidebar__item__text {
  color: white;
}
.vs-sidebar__item:after {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.vs-sidebar__item__icon {
  background-color: #111f2b !important;
  color: white;
}
.vs-sidebar__item {
  background-color: #111f2b !important;
}
.vs-sidebar-content .vs-sidebar__logo img {
  max-width: 200px !important;
  max-height: 38px !important;
}
.vs-sidebar__group.open > .vs-sidebar__group__header .vs-sidebar__item__arrow {
  transform: none !important;
}
#findings .vgt-right-align {
  text-align: left !important;
}
.vs-sidebar__item__icon {
  font-size: 1rem !important;
}
</style>
