<template>
  <div class="overview">
    <h2 v-if="this.$auth.isAuthenticated" style="padding-bottom: 10px">
      Overview
    </h2>
    <b-card class="text-center" style="max-width: 150px"
      ><span class="large-number">{{ outstandingCount }}</span>
      <br />
      outstanding {{ findingText() }}</b-card
    >
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Overview",
  components: {},
  data() {
    return {
      outstandingCount: undefined,
    };
  },
  methods: {
    findingText() {
      if (this.outstandingCount == 1) {
        return "finding";
      } else {
        return "findings";
      }
    },
  },
  created: async function () {
    const token = await this.$auth.getTokenSilently();
    axios
      .request({
        method: "post",
        url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
        data: {
          query: `query Open {
  findings_aggregate(where: {finding_audit: {audit_closed: {_eq: false}}}) {
    aggregate {
      count
    }
  }
}

`,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(
        (response) =>
          (this.outstandingCount =
            response.data.data.findings_aggregate.aggregate.count)
      );
  },
};
</script>

<style>
.large-number {
  font-size: 50px;
  text-align: center !important;
  font-weight: 800;
}
</style>
