<template>
  <div id="app">
    <div id="sidebar">
      <Sidebar />
    </div>
    <transition name="fade" mode="out-in">
      <router-view class="pagecontent" />
    </transition>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Sidebar from "../src/components/Sidebar";
// async function authPlugin({ opContext }) {
// opContext.headers.Authorization = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlRVMzNUV0hCSFAyRWVaVDdtNFQzQiJ9.eyJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6ImludGVybmFsIiwieC1oYXN1cmEtYWxsb3dlZC1yb2xlcyI6WyJpbnRlcm5hbCJdLCJ4LWhhc3VyYS11c2VyLWlkIjoiYXV0aDB8NjAzY2NlMWM0ZDVhZjUwMDcyMTE3ZjAyIiwieC1oYXN1cmEtb3JnLW5hbWUiOiJTa3kzNjAifSwiaXNzIjoiaHR0cHM6Ly9za3kzNjAuYXUuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDYwM2NjZTFjNGQ1YWY1MDA3MjExN2YwMiIsImF1ZCI6WyJza3lzd2FybS1zMy1kb3dubG9hZCIsImh0dHBzOi8vc2t5MzYwLmF1LmF1dGgwLmNvbS91c2VyaW5mbyJdLCJpYXQiOjE2MTgwNjI5NzgsImV4cCI6MTYxODE0OTM3OCwiYXpwIjoianp5d2s0OUZRWVRGR093eE1JU2NrQXAxcTlWMzZmdUMiLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIn0.Bys2Hny5xx_3QdSUx83Tbp7DaE5jwM2YRI-uFysz5beuwsUpymm3SgkNLNBEM8G5og7tc9iynoUJvIa_T-ARLUR8NhxKhhpTZFcrDF63XCn4YSIR8nmolcnVE9G1o7xpMu6bOTU8kaQXmLcOR3cBnaPDStlEEZzdCpUjYzjkUsco4I7BK4VmC429pO891jwKVmrf697-HCA1B_MfxQtrz0tn0Mv_FNbS-CEcq6hCNZgAn5dXLM846eiKEWkz39WpGi-Vx4vOoUN7H7Uw19ojGYADIjgRkCP8zEcdVJ6Yk6mnbeOJAGRfutRH6KAFG3_LA8YOMRffCETd-rpq8089Yg`;
// }

// import { useClient, handleSubscriptions, defaultPlugins } from "villus";

// import { SubscriptionClient } from "subscriptions-transport-ws";

// const subscriptionClient = SubscriptionClient(
//   "wss://graphql.api.skyswarm.sky360.com.au/v1/graphql"
// );
// const subscriptionForwarder = (operation) => subscriptionClient.request(op);

export default {
  components: {
    Sidebar,
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
  },
  // setup() {
  //   useClient({
  //     url: "https://graphql.api.skyswarm.sky360.com.au/v1/graphql",
  //     use: [
  //       authPlugin,
  //       handleSubscriptions(subscriptionForwarder),
  //       ...defaultPlugins(),
  //     ],
  //   });
  // },
};
</script>

<style>
#app {
  font-family: "Manrope", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 0 0 0;
}

h1 {
  font-weight: 100 !important;
}

.page-title {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
  margin-bottom: 0 !important;
  font-weight: 600;
}
/*  */
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

@media only screen and (max-device-width: 768px) {
  .pagecontent {
    margin-left: 100px;
    margin-right: 50px;
    margin-top: 50px;
  }
}
@media only screen and (min-device-width: 768px) {
  .pagecontent {
    margin-left: 310px;
    margin-right: 50px;
    margin-top: 50px;
  }
}

.pageheading {
  padding-left: 12px;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.vs-notification__content__text p {
  font-size: 14px !important;
}
.vs-notification__content__header h4 {
  font-size: 16px !important;
  font-weight: bold;
}
.vs-notification {
  border-radius: 10px !important;
}
.vs-notification__progress {
  background: white !important;
  opacity: 15% !important;
  height: 100% !important;
  z-index: -1;
}
.dropdown.show .dropdown-menu {
  -webkit-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
  -moz-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
  -ms-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
  -o-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
  transition: max-height 0.3s, opacity 0.2s, visibility 0s;

  max-height: 800px;
  opacity: 1;
  visibility: visible;
}
.dropdown-item.active {
  background-color: #b6ff00 !important;
}
/* .vgt-global-search__input .input__icon {
  display: none !important;
}
.vgt-pull-right {
  display: none !important;
}
.vgt-global-search__input {
  margin-left: 10px;
  margin-right: 10px;
} */
.status-tag {
  padding: 0.25em 1em;
  min-width: 80px;
  max-width: 80px;
  text-align: center;
  border-radius: 20px;
  width: max-content;
  color: white;
  font-size: 0.95rem;
  margin: -3px 0;
  font-weight: 600;
}
.tag-red {
  background-color: #ec2035;
}
.tag-green {
  background-color: #14bb3b;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
}
.redicon {
  color: red;
}
.greenicon {
  color: limegreen;
}
.vgt-responsive {
  border-radius: 0.25rem;
  border: 1px solid #dcdfe6;
}
table.vgt-table {
  border: none !important;
}
table.vgt-table td {
  border-radius: 0.25rem !important;
}
</style>
